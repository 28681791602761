var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('b-container',[_c('content-with-sidebar',{staticClass:"cws-container cws-sidebar-right blog-wrapper blogs-detail"},[(!_vm.loading)?_c('div',[_c('skeleton-details-blog',{attrs:{"loading":!_vm.loading}})],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"blog-detail-wrapper"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"img-src":_vm.blogDetail.coverImage
                  ? _vm.blogDetail.coverImage.fileUrl
                  : _vm.imgCover,"img-top":"","img-alt":"Blog Detail Pic","title":_vm.blogDetail.title,"img-height":"260px"}},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-body',[_c('small',{staticClass:"text-muted mr-50"},[_vm._v("posted in: ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.moment(_vm.blogDetail.createdAt).format("LL [at] h:mm A z")))])])],1),_c('div',{staticClass:"my-1 py-25"},_vm._l((_vm.tags),function(tag){return _c('b-link',{key:tag},[_c('b-badge',{staticClass:"mr-75",attrs:{"pill":"","variant":_vm.tagsColor(tag)}},[_vm._v(" "+_vm._s(tag)+" ")])],1)}),1),_c('div',{staticClass:"blog-content",domProps:{"innerHTML":_vm._s(_vm.blogDetail.content)}}),_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"d-flex align-items-center justify-content-between"})],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"blog-sidebar py-2 py-lg-0",attrs:{"slot":"sidebar"},slot:"sidebar"},[_c('div',{staticClass:"blog-recent-posts"},[_c('h6',{staticClass:"section-label mb-75"},[_vm._v(_vm._s(_vm.$t("common.RECENT_POSTS")))]),(!_vm.loading)?_c('div',[_c('skeleton-sidebar-blogs',{attrs:{"loading":!_vm.loading,"height":'147px'}})],1):_vm._e(),(_vm.loading)?_c('div',_vm._l((_vm.blogList),function(recentpost,index){return _c('b-media',{key:recentpost._id,class:index ? 'mt-2' : '',attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-link',{attrs:{"to":{
                    name: 'pages-blog-detail',
                    params: { id: recentpost._id },
                  }},on:{"click":function($event){return _vm.getBlogDetails()}}},[_c('b-img',{attrs:{"src":recentpost.files ? recentpost.files.fileUrl : null,"alt":recentpost.files ? recentpost.files.name : null,"width":"120","rounded":"","height":"80"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"blog-recent-post-title"},[_c('b-link',{staticClass:"text-body-heading",attrs:{"to":{
                      name: 'pages-blog-detail',
                      params: { id: recentpost._id },
                    }}},[_vm._v(" "+_vm._s(recentpost.title)+" ")])],1),_c('span',{staticClass:"text-muted mb-0"},[_vm._v(" "+_vm._s(_vm.moment(recentpost.createdAt).format("LL [at] h:mm A z"))+" ")])])],1)}),1):_vm._e()]),_c('div',{staticClass:"blog-categories mt-3"},[_c('h6',{staticClass:"section-label mb-1"},[_vm._v("Categories")]),_vm._l((_vm.categories),function(category){return _c('div',{key:category.index,staticClass:"d-flex justify-content-start align-items-center mb-75"},[_c('b-link',[_c('b-avatar',{staticClass:"mr-75",attrs:{"rounded":"","size":"32","variant":_vm.tagsColor(_vm.tags)}},[_c('feather-icon',{attrs:{"icon":category.icon,"size":"16"}})],1)],1),_c('b-link',[_c('div',{staticClass:"blog-category-title text-body"},[_vm._v(" "+_vm._s(category.category)+" ")])])],1)})],2)])])],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }