<template>
  <div>
    <Header />
    <b-container>
      <content-with-sidebar
        class="cws-container cws-sidebar-right blog-wrapper blogs-detail"
      >
        <div v-if="!loading">
          <skeleton-details-blog :loading="!loading" />
        </div>

        <!-- content -->
        <div class="blog-detail-wrapper" v-if="loading">
          <b-row>
            <!-- blogs -->
            <b-col cols="12">
              <b-card
                :img-src="
                  blogDetail.coverImage
                    ? blogDetail.coverImage.fileUrl
                    : imgCover
                "
                img-top
                img-alt="Blog Detail Pic"
                :title="blogDetail.title"
                img-height="260px"
              >
                <b-media no-body>
                  <!-- <b-media-aside vertical-align="center" class="mr-50">
                    <b-avatar
                      href="javascript:void(0)"
                      size="24"
                      :src="blogDetail.blog.avatar"
                    />
                  </b-media-aside> -->
                  <b-media-body>
                    <small class="text-muted mr-50">posted in: </small>
                    <!-- <small>
                      <b-link class="text-body">{{
                        blogDetail.blog.userFullName
                      }}</b-link>
                    </small> -->
                    <!-- <span class="text-muted ml-75 mr-50">|</span> -->
                    <small class="text-muted">{{
                      moment(blogDetail.createdAt).format("LL [at] h:mm A z")
                    }}</small>
                  </b-media-body>
                </b-media>
                <div class="my-1 py-25">
                  <b-link v-for="tag in tags" :key="tag">
                    <b-badge pill class="mr-75" :variant="tagsColor(tag)">
                      {{ tag }}
                    </b-badge>
                  </b-link>
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <div class="blog-content" v-html="blogDetail.content" />

                <!-- user commnets -->
                <!-- <b-media
                  v-for="user in blogDetail.blog.UserComment"
                  :key="user.avatar"
                  no-body
                >
                  <b-media-aside>
                    <b-avatar size="60" :src="user.avatar" />
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="font-weight-bolder">
                      {{ user.fullName }}
                    </h6>
                    <b-card-text>
                      {{ user.comment }}
                    </b-card-text>
                  </b-media-body>
                </b-media> -->
                <!-- eslint-enable -->
                <hr class="my-2" />

                <div class="d-flex align-items-center justify-content-between">
                  <!-- <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center mr-1">
                      <b-link class="mr-50">
                        <feather-icon
                          icon="MessageSquareIcon"
                          size="21"
                          class="text-body"
                        />
                      </b-link>
                      <b-link>
                        <div class="text-body">
                          {{ kFormatter(blogDetail.blog.comments) }}
                        </div>
                      </b-link>
                    </div>
                    <div class="d-flex align-items-center">
                      <b-link class="mr-50">
                        <feather-icon
                          size="21"
                          icon="BookmarkIcon"
                          class="text-body"
                        />
                      </b-link>
                      <b-link>
                        <div class="text-body">
                          {{ kFormatter(blogDetail.blog.bookmarked) }}
                        </div>
                      </b-link>
                    </div>
                  </div> -->

                  <!-- dropdown -->
                  <!-- <div class="blog-detail-share">
                    <b-dropdown
                      variant="link"
                      toggle-class="p-0"
                      no-caret
                      right
                    >
                      <template #button-content>
                        <feather-icon
                          size="21"
                          icon="Share2Icon"
                          class="text-body"
                        />
                      </template>
                      <b-dropdown-item
                        v-for="icon in socialShareIcons"
                        :key="icon"
                        href="#"
                      >
                        <feather-icon :icon="icon" size="18" />
                      </b-dropdown-item>
                    </b-dropdown>
                  </div> -->
                  <!--/ dropdown -->
                </div>
              </b-card>
            </b-col>
            <!--/ blogs -->

            <!-- blog comment -->
            <!-- <b-col id="blogComment" cols="12" class="mt-2">
              <h6 class="section-label">Comment</h6>
              <b-card
                v-for="(comment, index) in blogDetail.comments"
                :key="index"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar :src="comment.avatar" size="38" />
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="font-weight-bolder mb-25">
                      {{ comment.userFullName }}
                    </h6>
                    <b-card-text>{{ comment.commentedAt }}</b-card-text>
                    <b-card-text>
                      {{ comment.commentText }}
                    </b-card-text>
                    <b-link>
                      <div class="d-inline-flex align-items-center">
                        <feather-icon
                          icon="CornerUpLeftIcon"
                          size="18"
                          class="mr-50"
                        />
                        <span>Reply</span>
                      </div>
                    </b-link>
                  </b-media-body>
                </b-media>
              </b-card>
            </b-col> -->
            <!--/ blog comment -->

            <!-- Leave a Blog Comment -->
            <!-- <b-col cols="12" class="mt-2">
              <h6 class="section-label">Leave a Comment</h6>
              <div
                v-if="!$store.getters['auth/isAuth']"
                class="text-center py-1 text-muted"
              >
                <b-link to="/login">Login</b-link> to Comment
              </div>
              <b-card>
                <b-form>
                  <b-row>
                    <b-col sm="6">
                      <b-form-group class="mb-2">
                        <b-form-input name="name" placeholder="Name" />
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group class="mb-2">
                        <b-form-input
                          name="email"
                          type="email"
                          placeholder="Email"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group class="mb-2">
                        <b-form-input name="website" placeholder="Website" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group class="mb-2">
                        <b-form-textarea
                          name="textarea"
                          rows="4"
                          placeholder="Website"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="commentCheckmark"
                        name="checkbox-1"
                        class="mb-2"
                      >
                        Save my name, email, and website in this browser for the
                        next time I comment.
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                      >
                        Post Comment
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-col> -->
            <!--/ Leave a Blog Comment -->
          </b-row>
          <!--/ blogs -->
        </div>
        <!--/ content -->

        <!-- sidebar -->
        <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
          <!-- input search -->
          <!-- <b-form-group class="blog-search">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="search-input"
                v-model="search_query"
                placeholder="Search here"
              />
              <b-input-group-append class="cursor-pointer" is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group> -->
          <!--/ input search -->

          <!-- recent posts -->
          <div class="blog-recent-posts">
            <h6 class="section-label mb-75">{{ $t("common.RECENT_POSTS") }}</h6>

            <div v-if="!loading">
              <skeleton-sidebar-blogs :loading="!loading" :height="'147px'" />
            </div>
            <div v-if="loading">
              <b-media
                v-for="(recentpost, index) in blogList"
                :key="recentpost._id"
                no-body
                :class="index ? 'mt-2' : ''"
              >
                <b-media-aside class="mr-2">
                  <b-link
                    :to="{
                      name: 'pages-blog-detail',
                      params: { id: recentpost._id },
                    }"
                    @click="getBlogDetails()"
                  >
                    <b-img
                      :src="recentpost.files ? recentpost.files.fileUrl : null"
                      :alt="recentpost.files ? recentpost.files.name : null"
                      width="120"
                      rounded
                      height="80"
                    />
                  </b-link>
                </b-media-aside>
                <b-media-body>
                  <h6 class="blog-recent-post-title">
                    <b-link
                      class="text-body-heading"
                      :to="{
                        name: 'pages-blog-detail',
                        params: { id: recentpost._id },
                      }"
                    >
                      {{ recentpost.title }}
                    </b-link>
                  </h6>
                  <span class="text-muted mb-0">
                    {{
                      moment(recentpost.createdAt).format("LL [at] h:mm A z")
                    }}
                  </span>
                </b-media-body>
              </b-media>
            </div>
          </div>
          <!--/ recent posts -->

          <!-- categories -->
          <div class="blog-categories mt-3">
            <h6 class="section-label mb-1">Categories</h6>

            <div
              v-for="category in categories"
              :key="category.index"
              class="d-flex justify-content-start align-items-center mb-75"
            >
              <b-link>
                <b-avatar
                  rounded
                  size="32"
                  :variant="tagsColor(tags)"
                  class="mr-75"
                >
                  <feather-icon :icon="category.icon" size="16" />
                </b-avatar>
              </b-link>
              <b-link>
                <div class="blog-category-title text-body">
                  {{ category.category }}
                </div>
              </b-link>
            </div>
          </div>
          <!--/ categories -->
        </div>
      </content-with-sidebar>
    </b-container>

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import SkeletonDetailsBlog from "@/views/nftdubai/components/SkeltonDetailsBlog.vue";
import SkeletonSidebarBlogs from "@/views/nftdubai/components/SkeltonSidebarBlogs.vue";

import {
  BContainer,
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";

export default {
  components: {
    Header,
    Footer,
    BContainer,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    ContentWithSidebar,
    SkeletonDetailsBlog,
    SkeletonSidebarBlogs,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      imgCover: require("@/assets/images/blogs/cover.jpg"),
      search_query: "",
      commentCheckmark: "",
      blogDetail: [],
      blogList: [],

      blogSidebar: {},
      socialShareIcons: [
        "GithubIcon",
        "GitlabIcon",
        "FacebookIcon",
        "TwitterIcon",
        "LinkedinIcon",
      ],
      tags: ["NFT", "Marketplace"],
      categories: [
        { category: "NFT", icon: "WatchIcon" },
        { category: "Marketplace", icon: "ShoppingCartIcon" },
        { category: "Recruitment", icon: "CommandIcon" },
        { category: "Blockchain", icon: "HashIcon" },
        // { category: "Video", icon: "VideoIcon" },
      ],
    };
  },
  created() {
    this.getBlogDetails();

    this.getBlogs();
    // axios
    //   .get("posts/allBlogs/")
    //   .then((response) => (this.blogList = response.data.data));
  },
  mounted() {},
  methods: {
    kFormatter,
    moment: function (input) {
      return moment(input);
    },
    strippedContent: function (string) {
      string = string.replace(new RegExp("<[^>]*>", "g"), "");
      return string.replace(new RegExp("&nbsp;", "g"), " ");
    },
    tagsColor(tag) {
      if (tag === "NFT") return "light-info";
      if (tag === "Marketplace") return "light-primary";
      if (tag === "bb") return "light-danger";
      if (tag === "Video") return "light-warning";
      if (tag === "Food") return "light-success";
      return "light-primary";
    },

    getBlogDetails() {
      this.loading = false;
      axios
        .get(`posts/${this.$route.params.id}`)
        .then((response) => (this.blogDetail = response.data.data))
        .then(() => {
          this.loading = true;
        })
        .catch((error) => {
          this.$router.push({
            name: "pages-blog-list",
          });
        });
    },
    getBlogs() {
      const lang = localStorage.getItem("language") || "en";

      const formData = new FormData();
      // formData.append("q", searchQuery.value);
      // formData.append("page", currentPage.value);
      formData.append("perPage", 2);
      formData.append("language", lang);
      formData.append("type", "blog");

      axios.post("posts/search/", formData).then((response) => {
        this.blogList = response.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style scoped>
.blogs-detail {
  margin-top: 110px;
  margin-bottom: 110px;
  min-height: 780px;
}
</style>
